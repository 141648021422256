import React from 'react';
import ReactDOM from 'react-dom/client';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './index.css';
import { Admin, User } from './views';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<User />} />
        <Route path='/input_payment' element={<Admin />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);