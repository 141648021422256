import axios from "axios";
import { Component } from "react";

class Admin extends Component {

    constructor(){
        super()

        this.state = {
            murids: [],
            dataMurids: []
        }

        this.formatRupiah = this.formatRupiah.bind(this)
        this.muridsOnChange = this.muridsOnChange.bind(this)
        this.getKelas = this.getKelas.bind(this)
    }

    formatRupiah(angka, prefix)
    {
        var separator,
            number_string = angka.replace(/[^,\d]/g, '').toString(),
            split    = number_string.split(','),
            sisa     = split[0].length % 3,
            rupiah     = split[0].substr(0, sisa),
            ribuan     = split[0].substr(sisa).match(/\d{3}/gi);
            
        if (ribuan) {
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        
        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    getKelas(name){
        return this.state.dataMurids.find(v => v.nama === name).kelas
    }

    muridsOnChange(event){
        axios({
            url: '/api/murids',
            method: 'post',
            data: {
                name: event.target.value
            }
        }).then(v => {
            document.getElementById('kelas').value = v.data.data[0].kelas
            this.setState({
                murids: [...(v.data.data.map(v => v.nama) || [])],
                dataMurids: v.data.data
            })
        }).catch(console.dir)
    }

    sendData(event){
        event.preventDefault()
        document.querySelector('button').disabled = true

        var formData = new FormData(event.target)

        axios({
            url: '/api',
            method: 'post',
            data: formData
        }).catch(e => {
            console.log(e)
        })
        
        event.target.reset()
        alert('Berhasil')
        document.querySelector('button').disabled = false
    }

    render(){
        return (
            <div className="m-4">
                <form onSubmit={this.sendData} className="credit-card w-full sm:w-auto shadow-lg mx-auto rounded-xl bg-white">
                    <main className="mt-4 p-4">
                        <h1 className="text-xl font-semibold text-gray-700 text-center">Input payment</h1>
                        <div>
                            <div className="my-3">
                                <input
                                    type="text"
                                    name="nama"
                                    id="nama"
                                    className="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none"
                                    placeholder="Nama"
                                    minLength="2"
                                    onChange={this.muridsOnChange}
                                    required
                                />
                                {
                                    this.state.murids.length ? this.state.murids.map(v => {
                                        return (
                                            <ul className="bg-white border border-gray-100 w-full mt-2" key={v} onClick={() => {
                                                document.querySelector('#nama').value = v
                                                document.querySelector('#kelas').value = this.getKelas(v)
                                                this.setState({
                                                    murids: []
                                                })
                                            }}>
                                                <li className="pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer hover:bg-yellow-50 hover:text-gray-900">
                                                    <svg className="absolute w-4 h-4 left-2 top-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd"/>
                                                    </svg>
                                                    {v}
                                                </li>
                                            </ul>
                                        )
                                    }) : <></>
                                }
                            </div>
                            <div className="my-3">
                                <input
                                    type="text"
                                    name="kelas"
                                    id="kelas"
                                    className="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none"
                                    placeholder="Kelas"
                                    minLength="2"
                                    readOnly
                                />
                            </div>
                            <div className="my-3">
                                <input
                                    type="text"
                                    name="uang"
                                    className="block w-full px-5 py-2 border rounded-lg bg-white shadow-lg placeholder-gray-400 text-gray-700 focus:ring focus:outline-none"
                                    placeholder="Tambah / buat pembayaran"
                                    onKeyUp={(e) => {
                                        let value = e.target.value
                                        e.target.value = this.formatRupiah(value, 'Rp. ')
                                    }}
                                    required
                                />
                            </div>
                            <div>
                                <input name="bukti" className="block w-full text-gray-700 border rounded-lg cursor-pointer bg-white shadow-lg dark:text-gray-700 focus:outline-none file:mr-4 file:cursor-pointer file:rounded-md file:border-0 file:bg-teal-500 file:py-2 file:px-4 file:text-sm file:font-semibold file:text-white" type="file" />
                            </div>
                        </div>
                    </main>
                    <hr className="m-4 bg-teal-500"/>
                    <footer className="pr-4 pl-4 pb-4">
                        <button className="submit-button px-4 py-3 rounded-full bg-teal-500 text-white focus:ring focus:outline-none w-full text-xl font-semibold transition-colors">
                            Pay now
                        </button>
                    </footer>
                </form>
            </div>
        )
    }
}

export default Admin