import axios from "axios";
import { Component } from "react";
import { Link } from "react-router-dom";

class User extends Component {
    constructor(){
        super()

        this.state = {
            data: [],
            modal: false,
            modalContent: null
        }

        this.getData = this.getData.bind(this)
        this.formatRupiah = this.formatRupiah.bind(this)
        this.formatDate = this.formatDate.bind(this)

        this.getData()
    }

    getData(){
        axios({
            url: '/api',
            method: 'get',
        }).then(v => {
            this.setState({
                data: v.data
            })
        }).catch(console.log)
    }

    formatRupiah(angka, prefix)
    {
        var separator,
            number_string = angka.replace(/[^,\d]/g, '').toString(),
            split    = number_string.split(','),
            sisa     = split[0].length % 3,
            rupiah     = split[0].substr(0, sisa),
            ribuan     = split[0].substr(sisa).match(/\d{3}/gi);
            
        if (ribuan) {
            separator = sisa ? '.' : '';
            rupiah += separator + ribuan.join('.');
        }
        
        rupiah = split[1] !== undefined ? rupiah + ',' + split[1] : rupiah;
        return prefix === undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    render(){
        return (
            <div className="container p-4">
                <h1>Semua data pembayaran secara transparansi ada di table ini:</h1>
                <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
                    <thead className="text-white">
                        {
                            this.state.data.map(v => {
                                return (
                                    <tr className="bg-teal-400 flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0" key={v.nama}>
                                        <th className="p-3 text-left">Nama</th>
                                        <th className="p-3 text-left">Kelas</th>
                                        <th className="p-3 text-left">Uang</th>
                                        <th className="p-3 text-left" width="110px">Bukti</th>
                                        <th className="p-3 text-left">Status</th>
                                    </tr>
                                )
                            })
                        }
                    </thead>
                    <tbody className="flex-1 sm:flex-none">
                        {
                            this.state.data.map(v => {
                                return (
                                    <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0" key={v.nama}>
                                        <td className="border-grey-light border hover:bg-gray-100 p-3">{v.nama}</td>
                                        <td className="border-grey-light border hover:bg-gray-100 p-3">{v.kelas}</td>
                                        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">{this.formatRupiah(v.uang.toString(), 'Rp. ')}</td>
                                        <td className="border-grey-light border hover:bg-gray-100 p-3 text-blue-500 hover:text-blue-600 hover:font-medium cursor-pointer"><b onClick={() => {
                                            this.setState({
                                                modal: true,
                                                modalContent: <div className="flex justify-evenly gap-2 mb-5">
                                                    {v.images.map(image => {
                                                        return (
                                                            <div className="flex flex-col items-center" key={image.image_path}>
                                                                <img src={`/api/${image.image_path}`} alt={image.nama} className="w-52 h-42" />
                                                                <span className="font-bold">{this.formatDate(image.pay_on)}</span>
                                                            </div>
                                                        )
                                                    })}
                                                </div> 
                                            })
                                        }}>Click</b></td>
                                        <td className={`border-grey-light border hover:bg-gray-100 p-3 ${v.status ? 'text-green-600' : 'text-red-600'} hover:font-medium cursor-progress`}>{v.status ? 'Sudah Lunas' : 'Belum Lunas'}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className="w-full">
                   <Link to={'/input_payment'} id="redirected" hidden></Link> 
                    <button onClick={() => {
                        let password = prompt('Input the password as admin:')
                        if(password !== 'CacingBerkaki') return alert('Incorrected password')
                        document.querySelector('#redirected').click()
                    }} className="submit-button px-4 py-3 rounded-full bg-teal-500 text-white focus:ring focus:outline-none w-50 font-semibold transition-colors">
                        Input Data
                    </button>
                </div>
                <div className={`${this.state.modal ? '' : 'hidden'} flex md:justify-evenly flex-col p-8 w-fit h-max absolute m-auto bottom-0 top-0 left-0 right-0 bg-[#ffffff] shadow-2xl rounded-xl`}>
                    <i className="fa-solid fa-x absolute right-3 top-3 rounded-full p-2 cursor-pointer" onClick={() => this.setState({ modal: false })}></i>
                    {this.state.modalContent}
                </div>
            </div>
        )
    }
}

export default User